:root {
  /* CSS HEX */
  --primary: #0b132b;
  --secondary: #1c2541;
  --tertiary: #3a506b;
  --background: #5c7da7;
  --background-light: #5bc0be;
  --accent: #6fffe9;
  --accept: #56c49a;
  --danger: #433a6b;
  --secondary-light: #2c3b5b;
}

body {
  margin: 0;
  font-family: 'Exo 2', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  background-color: var(--background);
}

button {
  font-family: 'Exo 2', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
}

.app-title {
  color: var(--primary);
}

.app-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app-main {
  background-color: var(--primary);
  color: var(--background-light);
  border-radius: 10px;
  box-shadow: 5px 5px rgb(0 0 0 / 50%);
}

.display {
  margin: 1rem 1rem .5rem 1rem;
  background-color: var(--tertiary);
  font-size: 1.5rem;
  border-radius: 5px;
  padding: 0 3px;
}

.display-formula, .display-result {
  text-align: end;
  max-width: 500px;
  min-height: 30px;
  word-wrap: break-word;
}

.keyboard {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(4, 1fr);
  padding: 1rem;
}

button.key {
  cursor: pointer;
  padding: 1rem 0;
  background-color: var(--secondary);
  text-align: center;
  border-radius: 5px;
  min-width: 75px;
  max-width: 100%;
  color: var(--accent);
  font-size: 1rem;
}

button.key.cancel {
  background-color: var(--danger);
}

button.key.primary {
  background-color: var(--accept);
  font-weight: bold;
  color: var(--primary);
}

button.key.operation {
  background-color: var(--secondary-light);
}

.column-span-1 {
  grid-column: span 1;
}

.column-span-2 {
  grid-column: span 2;
}

.row-span-1 {
  grid-row: span 1;
}

.row-span-2 {
  grid-row: span 2;
}

.app-footer {margin: 5px;}